import React, { useState, useEffect } from 'react';
import { DataTable } from './data-table';
import { columns } from './columns';
import { posts } from '@/placeholder_data';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import x_icon from '@/assets/images/x-icon-white.png'

function Home() {
    const [posts, setPosts] = useState([]);
    const [accountsData, setAccountsData] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSave = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
            const response = await fetch('https://analytica-api.prydwen.ai/api/v1/scraper_account', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    scraper_account: {
                        username,
                        email,
                        password,
                    },
                }),
            });

            if (response.ok) {
                setOpen(false);
                const data = await response.json();
                setUsername(data.username);
                setPassword(data.password);

                setUserInfo(data);
            } else {
                const data = await response.json();
                setError(data.errors || 'Failed to update scraper account');
            }
        } catch (err) {
            setError('An error occurred while saving the scraper account');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
                const response = await fetch('https://analytica-api.prydwen.ai/api/v1/posts', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 401) {
                    // Redirect to login page on 401 Unauthorized
                    localStorage.removeItem('token');  // Optionally, clear the token
                    navigate('/login');  // Assuming you're using React Router
                    return;
                }

                if (response.ok) {
                    const data = await response.json();
                    setPosts(data);
                    console.log(data);
                } else {
                    setError('Failed to fetch data');
                }
            } catch (err) {
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://analytica-api.prydwen.ai/api/v1/scraper_account', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 401) {
                    // Redirect to login page on 401 Unauthorized
                    localStorage.removeItem('token');  // Optionally, clear the token
                    navigate('/login');  // Assuming you're using React Router
                    return;
                }

                if (response.ok) {
                    const data = await response.json();
                    setUsername(data.username);
                    setEmail(data.email);
                    setPassword(data.password);
                    setUserInfo(data);
                } else {
                    setError('Failed to fetch data');
                }

            } catch (err) {
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        }

        const fetchAccounts = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
                const response = await fetch('https://analytica-api.prydwen.ai/api/v1/accounts', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
  
                if (response.status === 401) {
                  // Redirect to login page on 401 Unauthorized
                  localStorage.removeItem('token');  // Optionally, clear the token
                  navigate('/login');  // Assuming you're using React Router
                  return;
                }
  
                if (response.ok) {
                    const resp = await response.json();
                    setAccountsData(resp);
                } else {
                    setError('Failed to fetch accounts data');
                }
            } catch (err) {
                setError('An error occurred while fetching accounts data');
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
        fetchAccounts();
        fetchUserInfo();
    }, []);

    return (
        <div className="px-12 py-24 mx-auto max-w-7xl space-y-4">
            <h1 className="text-2xl font-bold mb-4">Prydwen Analytica</h1>
            <div className="p-2 border rounded-lg">
                {userInfo.username ? (
                    <div className='inline-flex space-x-3 items-center'>
                        <p>Logged in as @{userInfo.username}</p>
                        <Button onClick={() => setOpen(true)} className='space-x-2'>
                            <img src={x_icon} className='object-contain w-4' alt="Change Account" />
                            <p>Change Account</p>
                        </Button>
                    </div>
                ) : (
                    <div className='inline-flex space-x-3 items-center'>
                        <p>No account</p>
                        <Button onClick={() => setOpen(true)} className='space-x-2'>
                            <img src={x_icon} className='object-contain w-4' alt="Add Account" />
                            <p>Add Scrape Account</p>
                        </Button>
                    </div>
                )}
            </div>
            <DataTable columns={columns} data={posts} accounts={accountsData} />

            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{userInfo.username ? 'Change Scrape Account' : 'Add Scrape Account'}</DialogTitle>
                        <DialogDescription>
                            {userInfo.username ? 'Change the username and password for the scraper account.' : 'Enter the username and password for the scraper account.'}
                        </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <Input 
                            placeholder="Username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                        />
                        <Input 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                        <Input 
                            placeholder="Password" 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <DialogFooter>
                        <Button onClick={handleSave} disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Home;
