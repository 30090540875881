import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button } from "@/components/ui/button";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
} from "@tanstack/react-table";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { useNavigate } from "react-router-dom";

const Edit = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentEdit, setCurrentEdit] = useState({ account: "", insight: "" });
    const [isEditing, setIsEditing] = useState(false);
    const [filter, setFilter] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
      const fetchAccounts = async () => {
          try {
              const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
              const response = await fetch('https://analytica-api.prydwen.ai/api/v1/accounts', {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              });

              if (response.status === 401) {
                // Redirect to login page on 401 Unauthorized
                localStorage.removeItem('token');  // Optionally, clear the token
                navigate('/login');  // Assuming you're using React Router
                return;
              }

              if (response.ok) {
                  const resp = await response.json();
                  setData(resp);
              } else {
                  setError('Failed to fetch data');
              }
          } catch (err) {
              setError('An error occurred while fetching data');
          } finally {
              setLoading(false);
          }
      };

      fetchAccounts();
    }, []);

    const handleAddAccount = () => {
        setCurrentEdit({ account: "", insight: "" });
        setIsEditing(false);
        setDialogOpen(true);
    };

    const handleEditAccount = (row) => {
        setCurrentEdit(row);
        setIsEditing(true);
        setDialogOpen(true);
    };

    const handleSaveAccount = async () => {
      if (isEditing) {
          // Handle editing logic here if necessary
          try {
              const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
              const response = await fetch(`https://analytica-api.prydwen.ai/api/v1/accounts/${currentEdit.id}`, {
                  method: 'PATCH', // Use PATCH for updating
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      account: {
                          insight: currentEdit.insight
                      }
                  })
              });

              if (response.ok) {
                  const updatedAccount = await response.json();
                  setData((prevData) =>
                      prevData.map((row) =>
                          row.id === updatedAccount.id
                              ? updatedAccount
                              : row
                      )
                  );
              } else {
                  const errorData = await response.json();
                  setError(errorData.errors ? errorData.errors.join(", ") : 'Failed to update account');
              }
          } catch (err) {
              setError('An error occurred while updating the account');
          }
      } else {
          // Add new account
          try {
              const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
              const response = await fetch('https://analytica-api.prydwen.ai/api/v1/accounts', {
                  method: 'POST',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      account: {
                          account: currentEdit.account,
                          insight: currentEdit.insight
                      }
                  })
              });

              if (response.ok) {
                  const newAccount = await response.json();
                  setData((prevData) => [...prevData, newAccount]);
              } else {
                  const errorData = await response.json();
                  setError(errorData.errors ? errorData.errors.join(", ") : 'Failed to add account');
              }
          } catch (err) {
              setError('An error occurred while adding the account');
          }
      }
      setDialogOpen(false);
    };

    const handleDeleteSelected = async () => {
      const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
      const selectedAccountIds = Object.keys(selectedRows).map(id => parseInt(id, 10));  // Ensure IDs are treated as numbers
    
      try {
          const response = await fetch('https://analytica-api.prydwen.ai/api/v1/accounts/destroy_multiple', {
              method: 'DELETE',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ account_ids: selectedAccountIds })
          });
    
          if (response.ok) {
              // Update the UI after successful deletion
              const newData = data.filter((row) => !selectedAccountIds.includes(row.id));
              setData(newData);
              setSelectedRows({});
          } else {
              setError('Failed to delete accounts');
          }
      } catch (err) {
          setError('An error occurred while deleting accounts');
      }
    };

    const handleFilterChange = useCallback((e) => {
      setFilter(e.target.value);
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setCurrentEdit((prevEdit) => ({
            ...prevEdit,
            [field]: value,
        }));
    }, []);

    const filteredData = useMemo(() => 
        data.filter((row) =>
            row.account.toLowerCase().includes(filter.toLowerCase())
        ),
        [data, filter]
    );

    const columns = useMemo(() => [
      {
          id: "select",
          header: ({ table }) => (
              <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
              />
          ),
          cell: ({ row }) => (
              <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => row.toggleSelected(!!value)}
              />
          ),
      },
      {
          accessorKey: "account",
          header: "Account",
      },
      {
          accessorKey: "insight",
          header: "Insight",
      },
      {
          id: "actions",
          header: "",
          cell: ({ row }) => (
              <Button variant="outline" onClick={() => handleEditAccount(row.original)}>
                  Edit
              </Button>
          ),
      },
  ], []);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
        rowSelection: selectedRows,
    },
    onRowSelectionChange: setSelectedRows,
    getRowId: (row) => row.id,  // Ensures each row is identified by its `id` field
  });

    return (
        <div className="px-12 py-24 mx-auto max-w-7xl space-y-4">
          <h1 className="text-2xl font-bold mb-4">Prydwen Analytica</h1>
          <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="inline-flex space-x-2">
                  <Button variant="outline" onClick={() => navigate("/")}>
                      Back
                  </Button>
                  <Input
                      placeholder="Search accounts..."
                      value={filter}
                      onChange={handleFilterChange}
                      className="max-w-sm"
                  />
                </div>
                <div className="inline-flex space-x-2">
                  <Button variant="outline" onClick={handleAddAccount}>
                      Add Account
                  </Button>
                  <Button
                      variant="destructive"
                      onClick={handleDeleteSelected}
                      disabled={!Object.keys(selectedRows).length}
                  >
                      Delete Selected
                  </Button>
                </div>
              </div>
              <div className="rounded-md border">
                  <Table>
                      <TableHeader>
                          {table.getHeaderGroups().map((headerGroup) => (
                              <TableRow key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => (
                                      <TableHead key={header.id}>
                                          {header.isPlaceholder
                                              ? null
                                              : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                      </TableHead>
                                  ))}
                              </TableRow>
                          ))}
                      </TableHeader>
                      <TableBody>
                          {table.getRowModel().rows?.length ? (
                              table.getRowModel().rows.map((row) => (
                                  <TableRow
                                      key={row.id}
                                      data-state={row.getIsSelected() && "selected"}
                                  >
                                      {row.getVisibleCells().map((cell) => (
                                          <TableCell key={cell.id}>
                                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                              ))
                          ) : (
                              <TableRow>
                                  <TableCell colSpan={columns.length} className="h-24 text-center">
                                      No results.
                                  </TableCell>
                              </TableRow>
                          )}
                      </TableBody>
                  </Table>
              </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
                <span>
                    Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </span>
            </div>

              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                  <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                          <DialogTitle>{isEditing ? "Edit Account" : "Add Account"}</DialogTitle>
                          <DialogDescription>
                              {isEditing ? "Edit the account details." : "Add a new account."}
                          </DialogDescription>
                      </DialogHeader>
                      <div className="grid gap-4 py-4">
                          <div className="grid grid-cols-4 items-center gap-4">
                              <Label htmlFor="account" className="text-right">
                                  Account
                              </Label>
                              <Input
                                  id="account"
                                  value={currentEdit.account}
                                  onChange={(e) => handleInputChange("account", e.target.value)}
                                  className="col-span-3"
                                  disabled={isEditing} // Disable editing the account name if editing
                              />
                          </div>
                          <div className="grid grid-cols-4 items-center gap-4">
                              <Label htmlFor="insight" className="text-right">
                                  insight
                              </Label>
                              <Input
                                  id="insight"
                                  value={currentEdit.insight}
                                  onChange={(e) => handleInputChange("insight", e.target.value)}
                                  className="col-span-3"
                              />
                          </div>
                      </div>
                      <DialogFooter>
                          <Button onClick={handleSaveAccount}>
                              {isEditing ? "Save Changes" : "Add Account"}
                          </Button>
                      </DialogFooter>
                  </DialogContent>
              </Dialog>
          </div>
      </div>
    );
};

export default Edit;
