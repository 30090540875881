export const tweets = [
    {
        flag: false,
        account: '@elonmusk',
        timestamp: new Date().toISOString(),
        content: "You should all go buy Doge coin right now!",
    },
    {
        flag: false,
        account: '@elonmusk',
        timestamp: new Date(Date.now() - 60000).toISOString(),
        content: "You should all go buy Doge coin right now!",
    },
    {
        flag: false,
        account: '@elonmusk',
        timestamp: new Date(Date.now() - 120000).toISOString(),
        content: "You should all go buy Doge coin right now!",
    },
    {
        flag: false,
        account: '@POTUS',
        timestamp: new Date(Date.now() - 180000).toISOString(),
        content: "Important announcement coming soon.",
    },
    {
        flag: false,
        account: '@realDonaldTrump',
        timestamp: new Date(Date.now() - 240000).toISOString(),
        content: "Make America Great Again!",
    }
];