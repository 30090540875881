import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, buttonVariants } from "@/components/ui/button";
import { AccountsCombobox } from "./AccountsCombobox";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter, SheetClose } from "@/components/ui/sheet";
import { Download, Pencil, ArrowUp, ArrowDown, ArrowUpDown } from "lucide-react";
import tweetImage from '@/tweet.png';

const convertToCSV = (data) => {
    // Get headers by extracting keys from the first row but excluding the "account" key
    const headers = Object.keys(data[0]).filter(key => key !== 'account');

    const csvRows = [];
    // Push headers into the first row
    csvRows.push(headers.join(","));

    // Push each row's values into the CSV rows, excluding the "account" key
    data.forEach(row => {
        const values = headers.map(header => {
            const val = row[header];
            return typeof val === "string" ? `"${val.replace(/"/g, '""')}"` : val; // Escape quotes in values
        });
        csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
};


// Helper function to download CSV file
const downloadCSV = (data, filename = 'data.csv') => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
    window.URL.revokeObjectURL(url); // Clean up the URL object
};

export function DataTable({ columns, data, accounts }) {
    const [accountFilter, setAccountFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedAccount, setSelectedAccount] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedTweet, setSelectedTweet] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState('');

    const downloadImage = async (url, filename) => {
        try {
            // Fetch the image from the URL
            const response = await fetch(url, { mode: 'cors' }); // Ensure CORS is allowed
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Convert the image to a Blob (binary data)
            const blob = await response.blob();
            
            // Create a temporary object URL for the Blob
            const urlBlob = window.URL.createObjectURL(blob);
    
            // Create a hidden <a> element, set its href to the Blob URL and trigger the download
            const link = document.createElement('a');
            link.href = urlBlob;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
    
            // Clean up the temporary Blob URL
            window.URL.revokeObjectURL(urlBlob);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download image:', error);
        }
    };

    // // Memoize filtered data to avoid recalculating on every render
    // const filteredData = useMemo(() => {
    //     // Filter by account if accountFilter is set
    //     let result = accountFilter
    //     ? data.filter((row) =>
    //         row.account.account.toLowerCase().includes(accountFilter.toLowerCase())
    //         )
    //     : data;

    //     // Filter by content if contentFilter is set
    //     if (contentFilter) {
    //     result = result.filter((row) =>
    //         row.content.toLowerCase().includes(contentFilter.toLowerCase())
    //     );
    //     }

    //     return result;
    // }, [accountFilter, contentFilter, data]);

    // Initialize table once with useMemo to avoid recreating it on each render
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
          sorting,
          columnFilters,
          rowSelection,
        },
    });

    const handleAccountFilterChange = useCallback((account) => {
        setSelectedAccount(account);
    
        console.log('Filtering by account:', account); // Debugging the selected account
    
        // Apply the account filter as lowercase (assuming the data might need normalization)
        table.getColumn("account")?.setFilterValue(account.toLowerCase());  // Convert account to lowercase for filtering
      }, [table]);  // Use useCallback to memoize the function

    const nextPage = useCallback(() => {
        table.nextPage();
    }, [table]);

    const previousPage = useCallback(() => {
        table.previousPage();
    }, [table]);

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
    
            // Calculate the next 10-minute mark
            const next10MinuteMark = new Date(now);
            const currentMinutes = now.getMinutes();
            const remainder = currentMinutes % 10;
            const minutesToAdd = remainder === 0 ? 10 : 10 - remainder;
            next10MinuteMark.setMinutes(currentMinutes + minutesToAdd, 0, 0); // Set to the next 10-minute interval
    
            // Calculate the difference in milliseconds
            const diff = next10MinuteMark - now;
    
            // Convert the time difference to minutes and seconds
            const minutes = Math.floor(diff / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    
            // Format the time remaining as a string
            setTimeRemaining(`${minutes}m ${seconds}s`);
        };
    
        // Update the countdown immediately and then every second
        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);
    
        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);
    
    // Helper function to format timestamp into a readable format
    const formatTimestamp = (timestamp) => {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        }).format(new Date(timestamp));
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center">
                <div className="inline-flex space-x-3">
                    <AccountsCombobox
                        value={accountFilter}
                        onChange={handleAccountFilterChange}
                        accounts={accounts}
                    />

                    {/* Input to search in "Content" column */}
                    <Input
                        placeholder="Search content..."
                        value={(table.getColumn("content")?.getFilterValue()) ?? ""}
                        onChange={(e) =>
                            table.getColumn("content")?.setFilterValue(e.target.value)
                        }
                        className="w-96"
                    />
                </div>

                <div className='flex justify-center space-x-3 ml-auto items-center'>
                    <p className="text-sm text-neutral-800">Next scrape in {timeRemaining}</p>
                    <Button
                        onClick={() => downloadCSV(data, "posts_data.csv")}
                    >
                        <Download className='mr-2 h-4 w-4' /> Export
                    </Button>
                    <Link to={'/edit'} className={buttonVariants({ variant: "outline" })}>
                        <Pencil className='mr-2 h-4 w-4' /> Edit Accounts
                    </Link>
                </div>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder ? null : (
                                            <Button
                                                variant="ghost"
                                                onClick={() => header.column.toggleSorting(header.column.getIsSorted() === 'asc')}
                                                className="flex items-center space-x-1"
                                            >
                                                <span>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </span>
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() === "desc" ? (
                                                        <ArrowDown className="h-4 w-4" />
                                                    ) : (
                                                        <ArrowUp className="h-4 w-4" />
                                                    )
                                                ) : (
                                                    <ArrowUpDown className="h-4 w-4" />
                                                )}
                                            </Button>
                                        )}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                    onClick={() => setSelectedTweet(row.original)}
                                    className="cursor-pointer"
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {cell.column.id === "timestamp" ? (
                                                formatTimestamp(cell.getValue()) // Format timestamp for display
                                            ) : cell.column.id === "account_account" ? (
                                                <span>@{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                                            ) : (
                                                flexRender(cell.column.columnDef.cell, cell.getContext())
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={previousPage}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={nextPage}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
            </div>

            {selectedTweet && (
                <Sheet open={!!selectedTweet} onOpenChange={setSelectedTweet}>
                    <SheetContent className=' w-[1000px]'>
                        <SheetHeader>
                            <SheetTitle>Tweet Details</SheetTitle>
                        </SheetHeader>
                        <div className="py-4">
                            {selectedTweet.image_url ? (
                                <img src={selectedTweet.image_url} alt="Tweet screenshot" className="w-full h-auto" />
                            ) : (
                                <p>No screenshot available.</p>
                            )}
                        </div>
                        <SheetFooter>
                            {selectedTweet.image_url ? (
                                <Button onClick={() => downloadImage(selectedTweet.image_url, "tweet_screenshot.png")}>
                                    Download Screenshot
                                </Button>
                            ) : (
                                <p>No screenshot available for download.</p>
                            )}
                            <SheetClose asChild>
                                <Button variant="outline" onClick={() => setSelectedTweet(null)}>
                                    Close
                                </Button>
                            </SheetClose>
                        </SheetFooter>
                    </SheetContent>
                </Sheet>
            )}
        </div>
    );
}
