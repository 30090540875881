import React, { useState, useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils.tsx";
import { Button } from "../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";

export function AccountsCombobox({ onChange, value, accounts }) {
    const [open, setOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(value);

    // Only call onChange when selectedAccount changes
    useEffect(() => {
        onChange(selectedAccount);
    }, [selectedAccount, onChange]);

    useEffect(() => {
        console.log(accounts);
    }, [accounts])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[200px] justify-between"
                >
                    {selectedAccount
                        ? accounts.find((account) => account.account.toLowerCase() === selectedAccount)?.account
                        : "Select account..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
                <Command>
                    <CommandInput placeholder="Search account..." />
                    <CommandList>
                        <CommandEmpty>No account found.</CommandEmpty>
                        <CommandGroup>
                            {accounts.map((account) => (
                                <CommandItem
                                    key={account.account.toLowerCase()}
                                    value={account.account.toLowerCase()}
                                    onSelect={() => {
                                        setSelectedAccount(account.account.toLowerCase());
                                        setOpen(false);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            selectedAccount === account.account.toLowerCase() ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {account.account}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
